<template>
	<div
		class="flex-column full-height bg-white position-relative"
	>
		<div class="text-center under-line bg-eee position-relative">
			<h5>{{ $date.getToday('-') }}</h5>
			<button class="position-absolute" style="top: 10px; right: 10px;" @click="getData" ><v-icon>mdi-refresh</v-icon></button>
		</div>

		<div
			v-if="is_deposit_account"
			class="full-height overflow-y-auto bg-gray-light pb-50"
		>
			<ul
				v-if="shop_list.length > 0"
			>
				<li
					v-for="(shop, s_index) in shop_list"
					:key="'shop_' + s_index"
					class=" mb-10 bg-white size-px-16"
				>
					<div
						class=" pa-10 justify-space-between-in-box"
					>
						<template
						>
						<div class="">
							<h6>은행명</h6>
							<div class="">{{ shop.bank_name}} </div>
						</div>
						<div
							class=""
							@click="copy(shop.shop_wallet)"
						>
							<h6 class="flex-1">가상계좌</h6>
							<div class="flex-2 word-break text-right"> {{ shop.shop_wallet }} <v-icon small>mdi-content-copy</v-icon></div>
						</div>
						</template>
						<div
							class=""
						>
							<h6 class="flex-1">예금주</h6>
							<div class="flex-2 word-break text-right">{{ shop.CUST_NM }}</div>
						</div>
					</div>
					<div
						class=" pa-10 justify-space-between-in-box top-line"
					>
						<div class="">
							<h6>입금 금액</h6>
							<div class=" text-right"> {{ item.deposit_balance | makeComma }}원</div>
						</div>
						<div><button class="full-width bg-identify ptb-5" @click="toDeposit">입금 내역</button></div>
					</div>
					<div
						class=" pa-10 justify-space-between-in-box top-line"
					>
						<div class="">
							<h6>출금 가능 금액</h6>
							<div class=" text-right"> {{ shop.balance | makeComma }}원</div>
						</div>
						<div
							v-if="shop.shop_wallet"
						>
							<h6>출금하기</h6>
							<div class="">
								<button @click="onWithdrawal(shop)" class="btn-inline btn-primary " :disabled="shop.is_disabled">출금</button>
								<button v-if="false" @click="onSend(shop)" class="btn-inline btn-primary ml-10" :disabled="shop.is_disabled">송금</button>
							</div>
						</div>
						<div class="mt-10"><button class="full-width btn-primary ptb-5" @click="toHistory">출금 내역</button></div>
					</div>
				</li>
			</ul>

			<Empty
				v-else
			>
				<template v-slot:body>
					등록된 지갑이 없습니다.
					<div class="mt-10">
						<button
							@click="onConfirm"

							class="btn-inline btn-primary"
						>지갑 생성</button>
					</div>
				</template>
			</Empty>

			<div
				v-if="false && deposit_account.bank_code"
				class=" mb-10 bg-white size-px-16"
			>
				<div class="pa-10 pb-10">
					<div class="justify-space-between items-center">
						<h6>입금 은행</h6>
						<div class="">{{ deposit_bank }} </div>
					</div>
					<div class="justify-space-between items-center">
						<h6 class="flex-1">계좌번호</h6>
						<div class="flex-2 word-break text-right"> {{ deposit_account.bank_account }}</div>
					</div>
					<div class="justify-space-between items-center">
						<h6 class="flex-1">예금주</h6>
						<div class="flex-2 word-break text-right"> {{ deposit_account.bank_holder }}</div>
					</div>
				</div>
			</div>
		</div>

		<empty
			v-else
			class="bg-white pa-20-10 font-weight-bold color-red-light"
		>
			<template v-slot:body>
				<div class="text-center">입금 계좌가 설정되지 않았습니다</div>
				<div class="mt-10">입금계좌는 본인명의의 계좌로 설정되어야 하며 <br/> 이를 위반할 시 서비스이용이 불가할 수 있습니다</div>

				<div class="mt-30 text-center">
					<button
						class="btn-inline btn-primary "
						@click="onDeposit"
					>입금 계좌 설정</button>
				</div>
			</template>
		</empty>

		<PopupConfirm
			v-if="is_on_wallet"
			@click="postWallet"
			@cancel="offWallet"
		>
			<template
				v-slot:title
			>지갑 생성</template>
			<template
				v-slot:main-txt
			>해당 상점의 지갑을 생성합니다.</template>
		</PopupConfirm>

		<UserAccount
			v-if="is_on_account"
			:user="user"
			:shop_info="item_account"

			@cancel="offAccount"
			@click="postAccount"
		></UserAccount>

		<UserWithdrawal
			v-if="is_on_withdrawal"
			:user="user"
			:shop_info="item_shop"
			:company_fee="company_fee"

			@click="offWithdrawal();getData();"
			@cancel="offWithdrawal"
		></UserWithdrawal>

		<UserSend
			v-if="is_on_send"
			:user="user"
			:shop_info="item_shop"

			@click="offSend();getData();"
			@cancel="offSend"
		></UserSend>

		<UserConfirm
			v-if="is_on_confirm"

			@cancel="is_on_confirm = false"
			@click="is_on_confirm = false; getData()"
		></UserConfirm>

		<AuthConfirm
			v-if="is_on_auth"
			confirm_type="wallet"

			@click="postWallet"
			@cancel="is_on_auth = false"
			@fail="fail"
		></AuthConfirm>

		<UserDepositWallet
			v-if="is_on_deposit"
			:user="user"

			@cancel="is_on_deposit = false"
			@click="is_on_deposit = false; getData()"
		></UserDepositWallet>
	</div>
</template>

<script>

import PopupConfirm from "../Layout/PopupConfirm";
import UserAccount from "./UserAccount";
import UserWithdrawal from "./UserWithdrawal";
import UserSend from "./UserSend";
import Empty from "../Layout/Empty";
import UserConfirm from "./UserConfirm";
import AuthConfirm from "@/view/Layout/AuthConfirm";
import UserDepositWallet from "@/view/Main/UserDepositWallet";

export default{
	name: 'IndexUser'
	, components: {
		UserDepositWallet,
		AuthConfirm, UserConfirm, Empty, UserSend, UserWithdrawal, UserAccount, PopupConfirm}
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, bottom: true
				, top: true
			}
			, item: {
				user_balance: 0
				, settlement_balance: 0
				, deposit_balance: 0
			}
			, items_shop: []
			, item_wallet: {}
			, item_account: {
				bank_code: ''
				, shop_uid: ''
			}
			, is_on_wallet: false
			, is_on_account: false
			, is_on_holder: false
			, is_on_withdrawal: false
			, item_shop: {}
			, company_fee: 0
			, agency_fee: 0
			, is_on_payment: false
			, is_on_send: false
			, is_on_confirm: false
			, is_on_auth: false
			, deposit_account: {
				bank_code: ''
			}
			, is_on_deposit: false
		}
	}
	,computed: {
		shop_list: function(){
			return this.items_shop.filter( (item) => {
				if(item.virtual_account){
					item.shop_wallet = item.virtual_account
				}else{
					item.is_btn_wallet = true
				}
				this.user.bank_list.filter( (bank) => {
					if(item.ORG_BANK == bank.bankId){
						item.bank_name = bank.bankName
					}
				})

				if(item.bank_account){
					this.user.bank_list.filter( (bank) => {
						if(item.bank_code == bank.bankId){
							item.bank_name = bank.bankName
						}
					})
					item.shop_account = item.bank_name + ' ' + item.bank_account + ' ' + item.bank_holder
				}else{
					item.is_btn_account = true
				}

				if(item.shop_balance <= 0 || item.is_block){
					item.is_disabled = true
				}
				return item
			})
		}
		, deposit_bank: function(){
			let t = ''
			if(this.deposit_account.bank_code){
				this.user.bank_list.filter( (bank) => {
					if(this.deposit_account.bank_code == bank.bankId){
						t = bank.bankName
					}
				})
			}
			return t
		}
		, is_wallet: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 1)){
				t = true
			}
			return t
		}
		, is_payment: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 2)) {
				t = true
			}

			return t
		}
		, is_card: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 3)) {
				this.items_shop.filter((item) => {
					if (this.$common.getPowNumber(item.sales_type, 3)) {
						t = true
					}
				})
			}
			return t
		}
		, is_manage: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 4)){
				t = true
			}
			return t
		}
		, is_agency: function(){
			let t = false
			if(this.user.agency_type == 'agency'){
				t = true
			}
			return t
		}
		, is_distributor: function(){
			let t = false
			if(this.user.agency_type == 'distributor'){
				t = true
			}
			return  t
		}
		, is_deposit_account: function(){
			let t = true

			if(this.deposit_account.bank_code){
				t = true
			}

			return t
		}

		, is_pinger: function(){
			let t = false

			if(this.user.virtual_code == 'pinger') {
				t = true
			}

			return t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: 'user/getUserMainInfo'
				})

				if (result.success) {
					this.items_shop = result.data.shop_list
					this.item.user_balance = result.data.user_balance
					this.item.deposit_balance = result.data.deposit_balance
					this.company_fee = result.data.company_fee
					this.agency_fee = result.data.upper_fee
					this.deposit_account = result.data.deposit_account
					this.$bus.$emit('addUserData', { key: 'bank_list', val: result.data.bank_list})
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				setTimeout( () => {
					this.$bus.$emit('on', false)
				}, 500)
			}
		}
		, postWallet: async function(item){

			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postWallet'
					, data: {
						type: item.auth_type
						, enc_data: JSON.stringify(item)
					}
				})

				if (result.success) {
					await this.getData()
					this.is_on_auth = false
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWallet: function(item){
			this.item_wallet = item
			this.is_on_wallet = true
		}
		, offWallet: function(){
			this.item_wallet = {}
			this.is_on_wallet = false
		}

		, postHolder: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postHolder'
					, data: this.item_account
				})

				if (result.success) {
					this.is_on_holder = true
					this.item_account.bank_holder = result.data.realBankOwnerName
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'check':
						this.postAccount(call)
						break;
				}
			})
		}
		, postCheck: function(){
			if(this.is_on_holder){
				this.onPin()
			}else{
				this.postHolder()
			}
		}
		, onAccount: function(item){
			this.item_account.shop_uid = item.shop_uid
			this.is_on_account = true
		}
		, offAccount: function(){
			this.item_account = {
				bank_code: ''
				, shop_uid: ''
			}
			this.is_on_holder = false
			this.is_on_account = false
		}
		, postAccount: function(){

			this.offAccount()
			this.getData()
		}
		, getBalance: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Axios({
					method: 'get'
					, url: 'user/getBalance'
				})

				if (result.success) {
					this.item.user_balance = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWithdrawal: function(shop){
			if(this.user.is_pin > 0){
				this.item_shop = shop
				this.is_on_withdrawal = true
			}else{
				alert('PIN 번호 설정후 이용하세요')
			}
		}
		, offWithdrawal: function(){
			this.item_shop = {}
			this.is_on_withdrawal = false
		}
		, onSend: function(shop){
			if(this.user.is_pin > 0) {
				this.item_shop = shop
				this.is_on_send = true
			}else{
				alert('PIN 번호 설정후 이용하세요')
			}
		}
		, offSend: function(){
			this.item_shop = {}
			this.is_on_send = false
		}
		, toPayment: function(shop){
			if(this.$common.getPowNumber(shop.sales_type, 3)){
				this.$bus.$emit('to', { name: 'PaymentAgree', params: {shop_uid: shop.shop_uid}})
			}else{
				this.$bus.$emit('to', { name: 'PaymentItem', params: {shop_uid: shop.shop_uid}})
			}
		}
		, toVirtual: function(){
			this.$bus.$emit('to', { name: 'VirtualIndex'})
		}
		, onConfirm: function(){
			// this.is_on_auth = true

			this.postWallet({
				auth_type: ''
			})
		}
		, fail: function(message){
			this.$bus.$emit('notify', { type: 'error', message: message})
		}

		,clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);
			t.value = val;
			t.select();
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (shop_wallet){
			this.clipBoard(shop_wallet);
			alert('계좌번호가 복사되었습니다.');
		}

		, onDeposit: function(){
			this.is_on_deposit = true
		}
		, toDeposit: function(){
			this.$bus.$emit('to', { name: 'WalletDepositHistory'})
		}
		, toHistory: function(){
			this.$bus.$emit('to', { name: 'WalletHistory'})
		}
	}
	,created: function(){
		this.getData()
		console.log(this.user)

		this.item_shop = this.user.shop_info
		this.program.title = this.item_shop.shop_name
		this.$set(this.program, 'shop_logo', this.item_shop.shop_logo)
		this.$bus.$emit('onLoad', this.program)
	}
}

</script>

